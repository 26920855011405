.about-image {
    width: 100%;
    height: auto;
    position: relative;
}
@media (max-width: 1100px) {
    .text-page-body.about{
        padding: 3vh 0 362px;
    }
}
@media (max-width: 880px) {
    .text-page-body.about{
        padding: 3vh 0 332px;
    }
}
@media (max-width: 640px) {
    .text-page-body.about{
        padding: 3vh 0 331px;
    }
}
@media (max-width: 596px) {
    .text-page-body.about{
        padding: 3vh 0 477px;
    }
}
@media (max-width: 509px) {
    .text-page-body.about{
        padding: 3vh 0 501px;
    }
}
@media (max-width: 450px) {
    .text-page-body.about{
        padding: 3vh 0 527px;
    }
}
@media (max-width: 380px) {
    .text-page-body.about{
        padding: 3vh 0 547px;
    }
}