.opt-cover {
  width: 100%;
  max-width: 1240px;
  margin: 70px auto 0;
  padding: 0 20px 50px;
  box-sizing: border-box;
  width: 100%;
}

.opt-h3 {
  margin: 45px 0 15px;
  font-size: 25px;
}

.contact-cover {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px 50px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}

.contact-form-cover {
  /*width: 50%;*/
  flex-basis: 53.231%;
}
.contact-picture {
  flex: auto;
  max-width: 61%;
}
.contact-form-cover label {
  position: relative;
}

.text-page-body .contact-form-cover h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  text-align: center;
  opacity: 0.94;
}

.contact-form-cover input,
.contact-form-cover textarea {
  width: 100%;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  opacity: 0.94;
  margin-bottom: 25px;
  padding: 10px 20px;
  box-sizing: border-box;
}
.contact-form-cover form {
  max-width: 471px;
}
.contact-form-cover textarea {
  height: 157px;
}
.contact-form-cover input::placeholder,
.contact-form-cover textarea::placeholder {
  color: #000;
  opacity: 0.94;
}

.contact-form-cover .main-btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.94;
  text-align: center;
  width: 100%;
  max-width: 320px;
  padding: 11px 0;
  border: 1px solid #cf2dea;
  background-color: #f0ad00;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  transition: all 0.3s linear;
}

.contact-form-cover button:hover {
  background-color: #ffd600;
}

.contact-img-cover {
  width: 55%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70vh;
}

.error_message {
  color: #e3a306;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  left: 3px;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 32px;
}

.show-success-message {
  display: flex;
  font-family: "Inter", sans-serif;
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 2px #000;
}

.hide-success-message {
  display: none;
}

.text-page-body.contact-bg {
  background-position: center right;
}

@media (max-width: 900px) {
  .contact-cover {
    flex-direction: column;
  }
  .contact-form-cover {
    width: 100%;
    max-width: 500px;
  }
  .contact-img-cover {
    height: 65vh;
    width: 100%;
    max-width: 500px;
  }
}
@media (max-width: 700px) {
  .contact-picture {
    max-width: 100%;
  }
}
@media (max-width: 580px) {
  .text-page-body .contact-form-cover h1,
  .contact-form-cover button {
    font-size: 28px;
    line-height: 34px;
  }
  .contact-img-cover {
    display: none;
  }
}
