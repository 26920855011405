@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap&family=Poppins:wght@400;500;600;700;800;900&display=swap&family=Roboto:wght@400;700&display=swap&family=Open+Sans:wght@400;500;600;700;800&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    position: relative;
}
.content-cover,
.container{
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.content-cover.legals{
    max-width: 900px;
    margin-bottom: 50px;
}
.text-page-body {
    min-height: 55vh;
    background-color: #1F0B71;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 1vh 0 377px;
    color: #fff;
    font-family: 'Open Sans';
    font-style: normal;
}

.text-page-body h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.94;
}

.text-page-body p,
.legals li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}
.text-page-body.about p{
    text-align: center !important;
}
.legals h3{
    opacity: 0.94;
}
.move-top {
    position: relative;
    /*top: -150px;*/
}

@media (max-width: 1480px) {
    .move-top {
        position: relative;
        /*top: -215px;*/
    }
}

@media (max-width: 1250px) {
    .move-top {
        top: -265px;
    }
}

@media (max-width: 1165px) {
    .move-top {
        position: relative;
        top: 0;
    }
}

@media (max-width: 880px) {
    .text-page-body {
        padding: 3vh 0 493px;
    }
}

@media (max-width: 500px) {
    .text-page-body h1 {
        font-size: 28px;
        line-height: 34px;
    }
    .text-page-body p {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
}

@media (max-width: 380px) {
    .text-page-body{
        padding: 3vh 0 547px;
    }
}