.top-page-body {
    background-color: #1F0B71;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
    padding: 3vh 0 355px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
}
.top-games-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: top;
}
.top-games-cover {
    width: 100%;
    margin: 4rem auto 4rem;
    box-sizing: border-box;
}
h1.top-games-title {
    position: relative;
    text-transform: uppercase;
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
}
.top-games-description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}
.top-games-top-block,
.top-games-bottom-block {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 25px 25px;
    gap: 25px 25px;
    width: 100%;
    margin: 15px auto;
}

.top-games-top-block .top-games-image-block,
.top-games-bottom-block .top-games-image-block{
    transition: all .5s;
    position: relative;
}

.top-games-image-block img {
    width: 100%;
    height: auto;
    max-width: 220px;
}
.top-games-image-block:hover img {
    filter: drop-shadow(0px 4px 10px rgba(93, 218, 244, 0.75));
}

.btn-block button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #f0ad00;
    width: 100%;
    max-width: 180px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #fff;
    padding: 14px 0;
    text-decoration: none;
    text-align: center;
    color: #fff;
    border: none;
    display: none;
    cursor: pointer;
    opacity: 0;
    transition: all .5s;
}

.top-games-image-block:hover .btn-block button {
    display: block;
    opacity: .94;
    z-index: 9;
}

.top-games-image-block button:hover {
    background: #FFD600;
}

@media (max-width: 640px) {

    .top-games-container {
        padding-bottom: 10rem;
    }
    .top-games-top-block,
    .top-games-bottom-block {
        flex-wrap: wrap;
    }
    h1.top-games-title{
        font-size: 28px;
        line-height: 34px;
    }
    .top-games-description {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .top-page-body{
        background-size: cover;
    }
}
