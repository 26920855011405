.contact-cover {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px 50px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
}

.contact-form-cover {
    /*width: 50%;*/
    flex-basis: 53.231%;
}
.contact-picture{
    flex: auto;
    max-width: 61%;
}
.contact-form-cover label {
    position: relative;
}

.text-page-body .contact-form-cover h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    text-align: center;
    opacity: 0.94;
}

.contact-form-cover input,
.contact-form-cover textarea {
    width: 100%;
    border: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000;
    opacity: 0.94;
    margin-bottom: 25px;
    padding: 10px 20px;
    box-sizing: border-box;
}
.contact-form-cover form{
    max-width: 471px;
}
.contact-form-cover textarea{
    height: 157px;
}
.contact-form-cover input::placeholder,
.contact-form-cover textarea::placeholder {
    color: #000;
    opacity: 0.94;
}

.contact-form-cover button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 57px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.94;
    text-align: center;
    width: 100%;
    max-width: 320px;
    padding: 11px 0;
    border: 1px solid #CF2DEA;
    background-color: #F0AD00;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    transition: all .3s linear;
}

.contact-form-cover button:hover {
    background-color: #FFD600;
}

.contact-img-cover {
    width: 55%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 70vh;
}

#name_error,
#email_error {
    top: 33px;
}

#message_error {
    top: -8px;
}

.error_message-show {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 3px;
    color: #e3a306;
    font-weight: 400;
    font-size: 14px;
}

.error_message-hide {
    display: none;
}

.hide-form {
    display: none;
}

.show-success-message {
    display: flex;
    font-family: 'Inter', sans-serif;
    margin-top: 100px;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 2px #000;
}

.hide-success-message {
    display: none;
}

.text-page-body.contact-bg {
    background-position: center right;
}

@media (max-width: 900px) {
    .contact-cover {
        flex-direction: column;
    }
    .contact-form-cover {
        width: 100%;
        max-width: 500px;
    }
    .contact-img-cover {
        height: 65vh;
        width: 100%;
        max-width: 500px;
    }
}
@media (max-width: 700px) {
    .contact-picture {
        max-width: 100%;
    }
}
@media (max-width: 580px) {
    .text-page-body .contact-form-cover h1,
    .contact-form-cover button{
        font-size: 28px;
        line-height: 34px;
    }
    .contact-img-cover {
        display: none;
    }
}