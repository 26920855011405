.sign-up-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
    top: 0;
    overflow: scroll;
}

.sign-up-overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .7;
    position: fixed;
    top: 0;
    left: 0;
}

.sign-up-body {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 554px;
    min-height: 450px;
    border: none;
    background: #1F0B71;
    backdrop-filter: blur(20px);
}

.sign-up-body.disable-form:hover,
.sign-up-body.disable-form *:hover {
    cursor: wait;
}

.sign-up-popup .sign-up-body h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    color: #FFFFFF;
    margin: 40px auto;
    text-align: center;
}

.sign-up-body form {
    width: 100%;
    max-width: 485px;
    margin: 0 auto;
}

.terms-checkbox-cover {
    position: relative;
    padding: 2px 0 25px;
}

.sign-up-body .box {
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid rgb(191, 191, 191);
    top: 19px;
    transform: translateY(-50%);
    left: 0px;
}

#terms,
#mailer {
    display: none;
}

.checkbox-text {
    margin-left: 30px;
    cursor: pointer;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.checkbox-text a {
    color: #0BE0C7;
}

.input-cover {
    position: relative;
}

.sign-up-body .box.checkbox {
    border-radius: 2px;
}

.sign-up-body input:checked~.box.checkbox::after {
    border-radius: 2px;
}

.sign-up-body input:checked~.box::after {
    content: '\2713';
    position: absolute;
    top: -3px;
    width: 12px;
    height: 12px;
    left: 50%;
    transform: translateX(-50%);
}

.sign-up-body .hidden{
    display: none;
}

.sign-up-body label {
    margin: 5px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-shadow: 1px 1px 3px #000;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #E4ECF9;
    line-height: 24px;
}

.sign-up-body form .input-cover input {
    width: 100%;
    padding: 14px 35px;
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #000;
}

.intl-tel-input {
    width: 100%;
}

.sign-up-body #register,
.sign-up-body #login {
    width: 100%;
    display: block;
    max-width: 345px;
    margin: 15px auto 0;
    background-color: #F0AD00;
    color: #fff;
    border: none;
    padding: 12px 0;
    line-height: 25px;
    transition: all .3s linear;
    cursor: pointer;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    opacity: 0.94;
}

.sign-up-body #register:hover,
.sign-up-body #login:hover {
    background-color: #FFD600;
}

.sign-up-body #register.disable,
.sign-up-body #register.disable:hover {
    opacity: .7;
    cursor: wait;
    background-color: #FF565E;
}

.form-text,
.form-text button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #fff;
}

.form-text button {
    text-decoration: underline;
    color: #05F2AF;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-popup-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close-popup-btn i {
    color: #fff;
    font-size: 36px;
}

.error_message-show {
    display: block;
}

.error_message-hide {
    display: none;
}

.sign-up-body form #email_error,
.sign-up-body form #password_error {
    top: 55px;
}

#terms_error {
    top: 33px;
}

.sign-up-body form .input-cover .error_input-hide {
    border: initial;
}

.sign-up-body form .input-cover .error_input-show {
    border: 2px solid #FF565E;
}

.sign-up-body form .input-cover {
    margin: 0 0 20px;
}

@media (max-width: 650px) {
    .sign-up-body {
        /*margin-left: -200px;*/
        /*width: 400px;*/
        /*min-height: 410px;*/
    }
    .sign-up-popup .sign-up-body h2 {
        font-size: 27px;
        line-height: 30px;
    }
    .sign-up-body form .input-cover>input {
        color: #353535;
        padding: 15px;
        margin: 0 0 15px;
        width: 98%;
    }
    .sign-up-body input:checked~.box::after{
        top: -6px;
        left: 60%;
    }
    .sign-up-body label {
        font-size: 14px;
    }
    .sign-up-body #register,
    .sign-up-body #login {
        padding: 8px 0;
        width: 98%;
        font-size: 16px;
    }
    .form-text,
    .form-text button {
        font-size: 14px;
        margin: 5px 0;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #password_error {
        top: 64px;
        font-size: 14px;
        font-weight: 600;
    }
    #terms_error {
        top: 33px;
        font-size: 14px;
        font-weight: 600;
    }
    .sign-up-body form .input-cover input {
        color: #353535;
        padding: 15px;
        margin: 0 0 15px;
        width: 98%;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #fullname_error,
    .sign-up-body form #password_error,
    .sign-up-body form #phone_error {
        top: 64px;
        font-size: 14px;
        font-weight: 600;
    }
}

@media (max-width: 450px) {
    .sign-up-body form .input-cover>input {
        padding: 11px 10px;
    }
    .sign-up-body {
        width: 92vw;
        min-height: 308px;
    }
    .sign-up-body form {
        width: 90%;
    }
    .sign-up-body label {
        font-size: 12px;
        margin: 2px 0 0;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #password_error {
        top: 53px;
        font-size: 12px;
    }
    #terms_error {
        top: 27px;
        font-size: 12px;
    }
    .sign-up-body .box {
        width: 12px;
        height: 12px;
        top: 13px;
    }
    .checkbox-text {
        margin-left: 20px;
        line-height: 18px;
    }
    .sign-up-popup .sign-up-body h2 {
        font-size: 16px;
        line-height: 14px;
        margin: 20px;
    }
    .sign-up-body #register,
    .sign-up-body #login {
        margin-top: 10px;
        padding: 5px 0;
        font-size: 15px;
    }
    .form-text,
    .form-text button {
        font-size: 11px;
        margin: 5px 0;
    }
    .close-popup-btn {
        top: -60px;
        right: 5px;
    }
    .close-popup-btn {
        top: -60px;
        right: 12px;
    }
    .sign-up-body form #email_error,
    .sign-up-body form #fullname_error,
    .sign-up-body form #password_error,
    .sign-up-body form #phone_error {
        top: 53px;
        font-size: 12px;
    }
    .sign-up-body form .input-cover input {
        padding: 11px 10px;
        font-size: 16px;
        line-height: 21px;
    }
}

@media (max-width: 360px) {
    .sign-up-body {
        width: 280px;
    }
}