.footer {
    width: 100%;
    padding: 27px 0;
    position: absolute;
    bottom: 0;
    background-color: #4C0FC0;
    z-index: 100;
}

.footer-cover {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
}
.footer-cover a{
    display: block;
    margin: 0 auto;
    max-width: 264px;
}
.footer-logo {
    width: 264px;
    margin: 0 auto 50px;
    display: block;
}

.footer h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 10px 0;
    text-align: center;
}

.footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    margin: 20px auto;
    text-align: center;
    width: 100%;
    max-width: 1240px;
}

.footer-nav {
    margin: 60px auto 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 764px;
}

.footer-nav li {
    list-style: none;
    color: #fff;
    position: relative;
}
.footer-nav li:after{
    content: '';
    position: absolute;
    top: 8px;
    right: -5px;
    width: 1px;
    height: 17px;
    background: #fff;
    z-index: 1;
}
.footer-nav li:last-child:after{
    display: none;
}

.footer-nav li a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.04em;
}

@media (max-width: 1100px) {
    .footer h2 {
        font-size: 22px;
    }
    .footer p {
        font-size: 14px;
        line-height: 25px;
        margin: 10px auto;
        max-width: 600px;
    }
    .footer-nav {
        margin: 20px auto;
        justify-content: center;
    }
    .footer-nav li a {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .footer-logo {
        position: static;
        display: block;
        margin: 0 auto 20px;
    }
}

@media (max-width: 600px) {
    .footer-nav li span {
        display: none;
    }
    .footer-nav {
        flex-direction: column;
    }
    .footer-nav li:after{
        display: none;
    }
    .footer h2 {
        font-size: 22px;
    }
    .footer p {
        opacity: 1;
    }
}