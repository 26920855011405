.game-cover iframe {
    width: 100%;
    height: 750px;
}

.game-headline {
    font-size: 25px;
}

.game-back-link {
    float: left;
    color: #fff;
    margin: 26px 0;
    cursor: pointer;
}

.game-back-link i {
    margin-right: 5px;
}

@media (max-width: 800px) {
    .game-cover iframe {
        height: 550px;
    }
    .game-cover {
        margin-bottom: 50px;
    }
}

@media (max-width: 700px) {
    .game-cover iframe {
        height: 500px;
    }
    .game-cover {
        margin-bottom: 150px;
    }
}

@media (max-width: 600px) {
    .game-cover iframe {
        height: 410px;
    }
}

@media (max-width: 500px) {
    .game-cover iframe {
        height: 350px;
    }
}

@media (max-width: 440px) {
    .game-cover iframe {
        height: 280px;
    }
    .game-cover {
        margin-bottom: 200px;
    }
}

@media (max-width: 380px) {
    .game-cover iframe {
        height: 220px;
    }
}