.thank-you-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 999;
    display: block;
    top: 0;
}

.thank-you-overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .7;
}

.thank-you-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 434px;
    height: 550px;
    background-size: cover;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.thank-you-popup .thank-you-body h2,
.thank-you-popup .thank-you-body p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    margin: 60px 0 10px;
    text-align: center;
    color: #0BE0C7;
    text-transform: uppercase;
    text-shadow: 3px 3px 3px #000;
}

.thank-you-popup .thank-you-body p {
    font-size: 19px;
    line-height: 27px;
    margin: 0 auto;
    display: block;
    max-width: 80%;
}

@media (max-width: 520px) {
    .thank-you-body {
        /*margin-left: -150px;*/
        max-width: 300px;
        height: 373px;
    }
    .thank-you-popup .thank-you-body h2 {
        font-size: 26px;
        line-height: 37px;
        margin: 30px 0 10px;
    }
    .thank-you-popup .thank-you-body p {
        font-size: 15px;
        line-height: 24px;
    }
}