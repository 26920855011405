.optout-form {
    width: 23rem;
    padding-top: 1%;
}
.text-page-body__userspage {
    min-height: 55vh;
    background-color: #1F0B71;
    padding: 1vh 0 380px;
    color: #fff;
    font-family: 'Open Sans';
    font-style: normal;
}
.main-banner-content-cover {
    padding: 20px 0 50px;
}
.users__contact-form-cover {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 0 100px;
}
.opt-out-cover .users__contact-form-cover .container {
    margin: 1rem auto 0;
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    padding: 0 15px;
}
.user-item {
    border-bottom: 1px dotted #fff;
    padding: 10px 0;
}
.user-item li {
    margin: 3px 0;
    list-style-type: none;
}

@media (max-width: 450px) {
    .text-page-body__userspage {
        min-height: 100vh;
        padding: 1vh 0 550px;
    }
}