.header {
  width: 100%;
  position: relative;
  background: #1e0759;
}

.header-cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  margin: 50px auto -1px;
  padding: 10px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.header-disclaimer {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background-color: #010506;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 20;
  box-sizing: border-box;
  padding: 17px 10px;
  text-align: center;
  position: fixed;
  width: 100%;
}

.navigation-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 444px;
  margin: 30px;
  padding: 0;
}

.navigation-list li {
  list-style: none;
}

.navigation-list li a {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s linear;
}

.navigation-list li a:hover {
  font-weight: 600;
}

.header .active {
  font-weight: 600;
}
.header-cover > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 335px;
  width: 100%;
}
.sign-up-button,
.login-btn,
.lp-sign-up-button,
.mobile-menu-login-btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  opacity: 0.94;
  max-width: 155px;
  width: 100%;
  border: none;
  padding: 9px 0px;
  background-color: #f0ad00;
  transition: all 0.3s linear;
  cursor: pointer;
  text-align: center;
}
.login-btn {
  border: 1px solid #fff;
  padding: 8px 0px;
  background-color: transparent;
}

.sign-up-button:hover,
.lp-sign-up-button:hover {
  background-color: #ffd600;
}
.login-btn:hover {
  background-color: #3b1f83;
}
.mobile-menu-btn {
  background-color: transparent;
  color: #ffffff;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  border: none;
  cursor: pointer;
  z-index: 21;
}
.nav-logo-mobile {
  display: none;
}
@media (max-width: 1100px) {
  .header-cover {
    padding: 10px 20px;
  }
  .navigation-list li a {
    font-size: 14px;
    line-height: 18px;
  }
  .header-cover > div {
    max-width: 290px;
  }
  .sign-up-button,
  .lp-sign-up-button,
  .login-btn,
  .mobile-menu-login-btn {
    font-size: 14px;
    line-height: 21px;
    width: 125px;
  }
  .header-logo {
    width: 240px;
  }
}
@media (max-width: 900px) {
  .nav-logo-mobile {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .header-logo {
    width: 200px;
  }
  .header-cover > div {
    max-width: 195px;
  }
  .sign-up-button,
  .lp-sign-up-button,
  .login-btn,
  .mobile-menu-login-btn {
    width: 90px;
  }
  .header-cover {
    justify-content: space-around;
    margin: 47px auto -1px;
  }
  .mobile-menu-btn {
    display: block;
    font-size: 33px;
  }
  .navigation-list {
    background: #1e0759;
    width: 100%;
    max-width: 100%;
    padding: 30px 0;
    flex-direction: column;
    position: absolute;
    top: -270px;
    transition: all 0.3s ease-in-out;
    z-index: 20;
  }
  .navigation-list.show-menu {
    top: -30px;
  }
  .navigation-list li {
    margin: 10px 0;
  }
  .header-logo {
    z-index: 15;
  }
  .header-disclaimer {
    font-size: 11px;
    line-height: 13px;
  }
}

@media (max-width: 450px) {
  .header-logo {
    width: 220px;
  }
}
