.container{
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}
.home-cover {
    width: 100%;
    height: 100%;
    background: #1E0759;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 250px;
}
.main-banner{
    width: 100%;
    height: auto;
    padding-top: 60px;
    background-repeat: no-repeat;
    background-position: 52% 100%;
    position: relative;
    background-size: cover;
}
.main-banner-content{
    width: 100%;
    max-width: 446px;
    min-height: 1000px;
    position: relative;
}
.main-banner-bottom-content{
    width: 100%;
    max-width: 1070px;
    margin: 0 auto;
}
.main-banner-content h2,
.main-banner-bottom-content h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.94;
}

.main-banner-content p,
.main-banner-bottom-content p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #FFFFFF;
    opacity: 0.94;
}
.main-banner-bottom-content p{
    text-align: center;
}
.main-banner-picture{
    width: 100%;
    max-width: 511px;
    margin: 60px 0 0 -23px;
    position: relative;
}
.banner-figure {
    width: 100%;
    height: 900px;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    top: 80vh;
    /*background-color: #0c1729;*/
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
}

.home-content {
    position: relative;
    z-index: 2;
    /*background-color: #0c1729;*/
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 20px 0 200px;
}

.banner-play-now-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.94;
    text-align: center;
    padding: 16px 0px;
    background: #F0AD00;
    /*filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.25));*/
    width: 100%;
    max-width: 411px;
    border: 1px solid #CF2DEA;
    transition: all .3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.banner-play-now-button:hover {
    background: #FFD600;
}

.display-banner-btn {
    display: block;
}

.mobile-banner-btn {
    display: none;
}

.ad-left-col,
.ad-right-col {
    width: 160px;
    height: 600px;
    border: none;
}

.ad-left-col iframe,
.ad-right-col iframe {
    width: 100%;
    height: 100%;
}

.featured-games {
    width: 98vw;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.featured-games .container{
    max-width: 1060px;
    margin: 0;
    padding: 0;
}

.featured-block-cover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.featured-block {
    width: 58%;
    position: relative;
}

.featured-block-image {
    width: 100%;
    position: absolute;
    top: -20px;
}

.featured-list {
    width: 37%;
}

.featured-list .games {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.featured-list .games .game {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    align-items: center;
    flex-direction: row;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .3s linear;
}

.featured-list .games .game:hover {
    background-color: #0F4D6A;
    opacity: .9;
}

.featured-list .games img {
    width: 18%;
    transition: all .3s linear;
    border-radius: 8px;
}

.featured-list .games .game:hover img {
    box-shadow: 0px 0px 22px #2ea384;
}

.featured-list .game-text {
    width: 59%;
}

.featured-list .game-text h3 {
    margin: 2px 0 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
}

.featured-list .game-text h4 {
    margin: 5px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9E9E9E;
}

.featured-list .featured-game-play {
    width: 72px;
    height: 44px;
    background: #0BE2A8;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: all .3s linear;
}

.featured-list .game .featured-game-play:hover {
    background: #D9525E;
}

.about-section {
    width: 100%;
    max-width: 1100px;
    padding: 70px 20px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-text {
    width: 55%;
    margin: 0 5% 0 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    float: left;
}

.about-sign-up-button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFAF9;
    background: #0BE2A8;
    border-radius: 21px;
    width: 175px;
    margin-top: 20px;
    padding: 18px 0;
    border: none;
    cursor: pointer;
    transition: all .3s linear;
}

.about-sign-up-button:hover {
    background-color: #D9525E;
}

.about-img {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
}

.about-img img {
    height: auto;
    width: 90%;
    position: relative;
    z-index: 1;
}

.squere {
    width: 160px;
    height: 160px;
    border-radius: 20px;
}

.red-squere {
    background-color: #D9525E;
    position: absolute;
    top: 0;
    left: 0;
}

.green-squere {
    background-color: #05F2AF;
    position: absolute;
    bottom: 0;
    right: 0;
}

.all-games {
    width: 100%;
    margin: 0 auto;
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}
.all-games-block-cover{
    width: 49%;
}
.all-games .all-games-block-right {
    width: 49%;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-wrap: wrap;
    gap: 10px 10px;
}
.all-games-block .top-game{
    width: 100%;
    /*max-width: 490px;*/
    margin: 10px 0;
    transition: all .3s linear;
}
.all-games img {
    transition: all .3s linear;
}

.all-games .vertical-games {
    width: 100%;
    max-width: 220px;
}

.all-games-block {
    position: relative;
    margin: 0 auto;
}
.featured-games-bottom-item,
.carousel-item{
    position: relative;
}
.featured-games-bottom-item img{
    width: 100%;
    max-width: 235px;
}
.featured-games-bottom-item .all-games-signup{
    left: calc(50% - 10px);
}
.all-games-block:hover img,
.featured-games-bottom-item:hover img,
.carousel-item:hover img,
.carousel-item-mob:hover img {
    filter: drop-shadow(0px 4px 50px rgba(207, 45, 234, 0.8));
}


.all-games-signup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #F0AD00;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border: none;
    display: none;
    cursor: pointer;
    opacity: 0;
    transition: all .3s linear;
}

.all-games-signup-btn-lg {
    width: 100%;
    max-width: 355px;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    padding: 18px 0;
    text-decoration: none;
}

.all-games-signup-btn-sm {
    width: 100%;
    max-width: 180px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 14px 0;
    text-decoration: none;
}

.all-games-signup:hover {
    background: #FFD600;
}

.all-games-block:hover .all-games-signup,
.featured-games-bottom-item:hover .all-games-signup,
.carousel-item:hover .all-games-signup,
.carousel-item-mob:hover .all-games-signup{
    display: block;
    opacity: 0.94;
    z-index: 9;
}
.featured-games-bottom-list{
    width: 100%;
    max-width: 1040px;
    margin: 50px auto 0;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*flex-wrap: wrap;*/
    /*gap: 10px 10px;*/
}
.carousel-game-icons{
    width: 100%;
    position: relative;
    margin: 100px auto;
    max-width: 1300px;
    /*background: radial-gradient(44.87% 44.87% at 50% 50%, #B14FFF 0%, rgba(148, 83, 255, 0) 100%)*/
}
.carousel-game-icons h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.94;
    text-align: center;
}
.carousel-item{
    background: #4C0FC0;
    padding: 5px 15px 0;
    width: 100%;
    max-width: 384px;
    min-height: 324px;
    position: relative;
}
.carousel-item>div{
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.carousel-item .image-wrap{
    position: relative;
}
.carousel-item .text-wrap{
    max-width: 209px;
    margin-left: 10px;
    height: 239px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.carousel-item h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 6px 0 0;
}
.carousel-item img{
    max-width: 172px;
}
.carousel-item p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    width: 100%;
    /*max-width: 200px;*/
    margin: 0px;
}
.carousel-item .all-games-signup-btn-sm,
.carousel-item-mob .all-games-signup-btn-sm{
    max-width: 80%;
    font-size: 16px;
    line-height: 16px;
}
.eclipse{
    background: radial-gradient(44.87% 44.87% at 50% 50%, #B14FFF 0%, rgba(148, 83, 255, 0) 100%);
    width: 100%;
    height: 841px;
    position: absolute;
    top: -100px;
}
.show-18-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 9999;
    display: block;
}

.popup-18-overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .7;
}

.popup-18-body {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    height: 382px;
    background-color: #220C75; /* For browsers that do not support gradients */
    background-image: linear-gradient(#220C75, #4A0FBC);
    background-repeat: no-repeat;
    background-position: center;
}

.popup-18-body h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
     text-align: center;
    color: #FFFFFF;
    width: 100%;
    max-width: 449px;
    margin: 40px 0 40px 35px;
}

.checkbox-cover {
    position: relative;
    padding: 0 35px;
    box-sizing: border-box;
}

.checkbox-cover,
.checkbox-cover a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    width: 100%;
    /*text-align: center;*/
}

.checkbox-cover label {
    padding: 0px 30px 0px 7px;
    position: relative;
    cursor: pointer;
}

.checkbox-cover input {
    opacity: 0;
}

.box {
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid rgb(191, 191, 191);
    top: 12px;
    transform: translateY(-50%);
    left: 0px;
}

.box.checkbox {
    border-radius: 2px;
}

input:checked~.box.checkbox::after {
    border-radius: 2px;
}

input:checked~.box::after {
    content: '\2713';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    /*top: 50%;*/
    transform: translate(-50%, -50%);
    /*background-color: #05F2AF;*/
}

.hidden {
    display: none;
}

.popup-18-body .buttons {
    display: flex;
    width: 100%;
    max-width: 470px;
    padding: 40px 0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.popup-18-body .buttons button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #FFFFFF;
    opacity: 0.94;
    text-align: center;
    border: 1px solid #05F2AF;
    padding: 5px 0;
    background-color: transparent;
    transition: all .3s linear;
    width: 100%;
    max-width: 220px;
    cursor: pointer;
}
#button-yes{
    background: linear-gradient(180deg, #F0AD00 0%, #F0AD00 100%);
}
#button-yes:hover{
    background: linear-gradient(180deg, #F0AD00 0%, #FFD600 100%);
}
#button-no:hover {
    background-color: #D9525E;
    color: #fff;
    border-color: #D9525E;
}
.hide-18-popup {
    display: none;
}

.show-error,
.hide-error {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #D9525E;
    font-weight: 600;
    position: absolute;
    top: 30px;
    left: 14%;
}

.show-error {
    display: block;
}

.hide-error {
    display: none;
}

.footer-ad {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-footer {
    width: 100%;
    max-width: 728px;
    height: 90px;
    border: none;
}

.sclick-id-style {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    display: none;
}
.ad-banner{
    width: 100%;
    max-width: 1100px;
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
}
.ad-2 {
    width: 728px;
    height: 90px;
    border: none;
    margin: 0;
    z-index: 99;
}
@media (min-width: 1450px){
    .main-banner{
        background-position: 55% 20%;
        position: relative;
        background-size: auto;
    }
}


@media (max-width: 1450px) {
    .home-content {
        /*padding: 80px 0 90px;*/
    }
    .about-section {
        max-width: 850px;
        padding: 30px 20px;
    }
    .about-img {
        height: 310px;
    }
    .featured-list .games img {
        margin-right: 5px;
    }
}
@media (max-width: 1370px) and (max-height: 770px){
    .featured-games .container {
        max-width: 1000px;
        margin: 0;
        padding: 0 20px;
    }
}
@media (max-width: 1280px) and (max-height: 720px){
    .featured-games .container {
        max-width: 930px;
        margin: 0;
        padding: 0 20px;
    }
    .all-games .vertical-games {
        max-width: 200px;
    }
}
@media (max-width: 1250px) {
    .about-section {
        max-width: 730px;
    }
    .featured-block-cover {
        width: 65%;
    }
    .about-img {
        width: 40%;
        height: 250px;
    }
    .home-content {
        padding: 80px 0 30px;
    }
}

@media (max-width: 1165px) {
    .featured-block {
        width: 100%;
    }
    .featured-list {
        width: 100%;
    }
    .ad-left-col,
    .ad-right-col {
        width: 30%;
    }
    .featured-block-image {
        top: 3px;
    }
    .ad-left-col,
    .ad-right-col {
        display: none;
    }
    .all-games .vertical-games,
    .featured-games-bottom-item img {
        max-width: 200px;
    }
    .featured-games {
        justify-content: center;
    }
    .featured-block-cover {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        flex-direction: column;
    }
    .featured-list,
    .featured-block {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        min-height: 350px;
    }
    .all-games-signup-btn-sm {
        max-width: 160px;
        font-size: 20px;
        line-height: 25px;
        padding: 10px 0;
    }
    .featured-games-bottom-item .all-games-signup{
        left: calc(50% - 20px);
    }
    .home-content {
        padding: 80px 0 350px;
    }
}

@media (max-width: 1000px) {
    .about-img {
        height: 260px;
    }
}

@media (max-width: 900px) {
    .about-img {
        height: 310px;
    }
    .featured-block-image {
        position: absolute;
        top: -20px;
    }
    .featured-list {
        width: 40%;
    }
    .featured-list .game-text h3 {
        font-size: 16px;
        line-height: 22px;
    }
    .featured-list .game-text h4 {
        font-size: 13px;
        line-height: 15px;
    }
    .featured-list .game-text {
        margin-left: 5px;
    }
    .all-games-signup-btn-sm {
        /*bottom: 25px;*/
        width: 130px;
        font-size: 20px;
        line-height: 17px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .all-games-signup-btn-lg {
        width: 300px;
        font-size: 40px;
        line-height: 42px;
    }
    .featured-games {
        justify-content: center;
        flex-direction: column;
    }
    .featured-list,
    .featured-block {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        min-height: 350px;
    }
    .all-games .vertical-games{
        max-width: 176px;
    }
}

@media (max-width: 800px) {
    .banner-figure {
        height: 500px;
    }
    .all-games {
        flex-direction: column;
    }
    .all-games-block-cover,
    .all-games .all-games-block-right{
        width: 100%;
    }
    .about-img {
        width: 27%;
    }
    .about-text {
        width: 68%;
    }
    .about-img {
        height: 185px;
    }
    .squere {
        width: 60px;
        height: 60px;
    }
    .footer-ad {
        display: none;
    }
    .display-banner-btn {
        display: none;
    }
    .mobile-banner-btn {
        display: block;
    }
    .home-content {
        padding: 0 0 100px;
    }
}

@media (max-width: 700px) {
    .home-cover{
        padding-bottom: 150px;
    }
    .main-banner{
        padding: 0;
    }

    .main-banner-content h2, .main-banner-bottom-content h2{
        font-size: 28px;
        line-height: 34px;
        text-align: center;
    }
    .main-banner-content h2{
        padding-top: 45px;
    }
    .main-banner-content p, .main-banner-bottom-content p{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
    .carousel-game-icons h2{
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        max-width: 320px;
        margin: 20px auto;
    }
    .carousel-game-icons{
        margin: 0 auto;
    }
    .carousel-item .all-games-signup-btn-sm,
    .carousel-item-mob .all-games-signup-btn-sm{
        max-width: unset;
        margin-top: -165px;
    }
    .about-img {
        display: none;
    }
    .about-text {
        width: 100%;
        margin: 0;
    }
    .about-text p {
        text-align: justify;
    }
    .banner-play-now-button {
        font-size: 36px;
        line-height: 44px;
        margin: auto;
        position: absolute;
        top: -49px;
        left: 50%;
        transform: translateX(-50%);
    }
    .home-content {
        padding: 80px 0 440px;
    }
    .main-banner-picture{
        margin: 10px auto 0;
        max-width: 100%;
    }
    .main-banner-content {
        min-height: 100%;
        height: 100%;
        max-width: 100%;
    }
    .featured-games-bottom-item .all-games-signup{
        left: calc(50% - 62px);
    }
    .all-games-block .top-game{
        max-width: 90%;
        margin: 0 auto 10px;
        display: block;
    }
    .carousel-item-mob{
        background: #4c0fc0;
        padding: 20px 0;
        width: 100%;
        min-height: 450px;
        max-width: 376px;
        position: relative;
    }
    .carousel-item-mob>div{
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }
    .carousel-item-mob h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #fff;
    }
    .carousel-item-mob p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #FFFFFF;
        width: 100%;
    }
    .carousel-item-mob>div img,
    .carousel-item-mob p{
        max-width: 100%;
        margin: auto;
    }
    .all-games-signup-btn-lg {
        width: 130px;
        font-size: 20px;
        line-height: 17px;
    }

    .ad-1{
        width: 300px;
        height: 250px;
    }
}
@media (max-width: 640px){
    .carousel-item-mob .all-games-signup-btn-sm {
        margin-top: -110px
    }
}
@media (max-width: 540px) {
    .featured-list,
    .featured-block {
        min-height: 300px;
    }
    .popup-18-body {
        width: 300px;
        height: 192px;
        background-size: contain;
    }
    .popup-18-body h2 {
        font-size: 21px;
        line-height: 30px;
        margin: 28px 0 10px;
        text-align: center;
    }
    .checkbox-cover label {
        font-size: 14px;
        padding: 0;
    }
    .checkbox-cover,
    .checkbox-cover a {
        font-size: 14px;
    }
    .box {
        width: 12px;
        height: 12px;
        top: 8px;
    }
    .popup-18-body .buttons {
        padding: 0;
        justify-content: space-around;
        margin-top: 18px;
    }
    .popup-18-body .buttons button {
        width: 100px;
        padding: 5px 0;
        font-size: 15px;
        line-height: 28px;
    }
    .show-error,
    .hide-error {
        font-size: 13px;
        top: 20px;
        left: 10%;
    }
    input:checked~.box::after {
        width: 10px;
        height: 10px;
        top: -4px;
    }
    .home-content {
        padding: 0 0 480px;
    }
    .all-games .vertical-games{
        max-width: 280px !important;
        width: 280px;
    }
    .carousel-item-mob>div {
        max-width: 280px;
    }
    .all-games-block .top-game {
        max-width: 280px;
    }
}

@media (max-width: 480px) {
    .banner-figure {
        top: 89vh;
    }
    .home-content {
        padding: 30px 0 380px;
    }
    .featured-games-bottom-item .all-games-signup{
        left: calc(50% - 25px);
    }
    .featured-list,
    .featured-block {
        min-height: 290px;
    }
    .featured-block-image {
        top: 0;
        position: static;
    }
    .featured-block h2 {
        margin-bottom: 0;
    }
    .banner-play-now-button {
        max-width: 320px;
    }
    .home-content {
        padding: 0 0 450px;
    }
     .carousel-item-mob .all-games-signup-btn-sm {
        margin-top: -110px;
    }
}

@media (max-width: 420px) {
    .featured-games-bottom-item .all-games-signup{
        left: calc(50% - 15px);
    }
}
@media (max-width: 380px) {
    .featured-games-bottom-item .all-games-signup{
        left: calc(50% - 8px);
    }
    /*.main-banner-content {*/
    /*    min-height: 70vh;*/
    /*}*/
}