.lp-home-cover {
    width: 100%;
    height: 100%;
    background: #1B0033;
    position: relative;
    padding-bottom: 380px;
    background-position: bottom;
    background-size: cover;
}

.lp-banner {
    position: relative;
    width: 100%;
    padding: 10px 0 120px;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
}

.lp-home-cover h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 58px;
    text-align: center;
    color: #F6F4F4;
    margin: 0 0 20px;
}

.lp-content-cover {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.steps-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 45px 0;
}

.step-block {
    position: relative;
    width: 100%;
    max-width: 362px;
    min-height: 563px;
    padding: 40px;
    border-top: 1px solid #F0AD00;
    border-bottom: 1px solid #F0AD00;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s;
}

.step-block:hover {
    box-shadow: 0px 0px 22px #2ea384;
}

.step-text h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin: 0;
    text-transform: uppercase;
}

.step-text p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.step-number {
    position: absolute;
    top: -14px;
    left: -14px;
    border: 5px solid #FF7C87;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 50px;
    border-radius: 9px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #626381;
}

.lp-play-now {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    color: #fff;
    opacity: .94;
    text-align: center;
    padding: 16px 0;
    background: #f0ad00;
    width: 100%;
    max-width: 351px;
    margin: 0 auto;
    border: 1px solid #cf2dea;
    transition: all .3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: relative;
    z-index: 1;
}

.lp-play-now:hover {
    background: #FFD600;
}
.lp-description {
    width: 100%;
    margin: 130px 0 100px;
    padding: 25px 110px;
    background-color: #fff;
    opacity: .9;
    box-sizing: border-box;
    border-radius: 20px;
}

.lp-description p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #626381;
}

.lp-games-section {
    display: flex;
    align-items: center;
    margin: 3rem auto;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;
}

/*.lp-game-cover {*/
/*    width: 29%;*/
/*}*/

.lp-game-cover-wide {
    width: 37%;
}

.lp-game-cover,
.lp-game-cover-wide .lp-game {
    margin: 0 auto 25px;
}

.lp-game {
    position: relative;
    cursor: pointer;
}

.lp-game img {
    width: 100%;
}

.lp-game .open-game {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #f0ad00;
    transition: all .2s linear;
    z-index: 2;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    padding: 14px 0;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.lp-game .open-game:hover {
    background: #FFD600;
}
.lp-game:hover img{
    box-shadow: 0px 0px 22px #2ea384;
}
.lp-game:hover .open-game {
    opacity: 1;
}
.super-game-cover{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: block;
    padding-bottom: 90px;
}
.super-game-cover h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 65px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.94;
    margin: 50px auto;
}
.super-game{
    display: flex;
    flex-direction: column;
    max-width: 589px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.super-game .image-block img{
    max-width: 100%;
}
.super-game .text-block{
    background: #4C0FBF;
    margin-top: -5px;
}
.super-game .text-block p{
    font-family: 'Inter';
    max-width: 495px;
    margin: 25px auto;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.94;
}
.signup-btn{
    width: 100%;
    max-width: 360px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #fff;
    padding: 13px 0;
    text-decoration: none;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #f0ad00;
    border: 3px solid #CF2DEA;
    cursor: pointer;
    transition: all .3s;
    text-align: center;
}
.signup-btn:hover{
    background: #FFD600;
}

.desktop {
    display: inline;
}

.mobile {
    display: none;
}
@media (max-width: 1100px) {
    .lp-banner {
        background-size: 100% 155%;
    }
    .step-block{
        max-width: 30%;
        margin: 0 auto;
        border-left: 1px solid #F0AD00;
        border-right: 1px solid #F0AD00;
    }
    .lp-home-cover h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .step-text {
        max-width: 80%;
    }
    .lp-description {
        padding: 25px 50px;
    }
    .lp-games-section {
        margin: 50px auto;
    }
    .page-carousel h2 {
        font-size: 28px;
    }
    .lp-home-cover {
        padding-bottom: 350px;
    }
    .owl-text {
        font-size: 20px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .lp-play-now {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
    }
}

@media (max-width: 850px) {
    .lp-content-cover{
        padding: 0 20px;
    }
    .step-block:hover {
        box-shadow: unset;
    }
    .lp-home-cover h1 {
        font-size: 28px;
        line-height: 38px;
    }
    .steps-cover {
        margin: 22px 0;
    }
    .steps-cover {
        flex-direction: column;
    }
    .step-block {
        width: 100%;
        max-width: 90%;
        min-height: auto;
        padding: 0px;
        text-align: left;
        border: none;
        display: flex;
        align-items: center;
    }
    .lp-banner .step-img{
        position: absolute;
        width: 100%;
        bottom: -21%;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .lp-home-cover {
        padding-bottom: 450px;
    }
    .super-game-cover .img{
        width: 100%;
        max-width: 214px;
        margin: 0 auto;
        display: block;
    }
    .super-game-cover .img.top{
        transform: translateY(20%);
        margin-top: -10%;
    }
}
@media (max-width: 850px) and (max-height: 400px){
    .lp-content-cover.games{
        margin-top: 25%;
    }
    .lp-banner .step-img {
        top: 50%;
    }
}
@media (max-width: 790px) and (max-height: 450px){
    .lp-content-cover.games{
        margin-top: 25%;
    }
    .lp-banner .step-img {
        top: 60%;
        max-width: 700px;
    }
}
@media (max-width: 640px){
    .lp-banner .step-img{
        bottom: -12%;
    }
    .super-game-cover .img.top{
        transform: translateY(20%);
        margin-top: -15%;
    }
}

@media (max-width: 460px) {
    .lp-home-cover {
        padding-bottom: 550px;
    }
    .lp-content-cover.games {
        margin-top: 20%;
    }
    .lp-home-cover h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        margin: 30px 0;
        text-align: center;
    }
    .steps-cover {
         margin: 0;
    }
    .step-block {
        padding-top: 10px;
        max-width: 100%;
    }
    .step-block img{
        max-width: 121px;
    }
    .step-text h3 {
        font-size: 22px;
        line-height: 27px;
    }
    .step-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }
    .lp-play-now{
        max-width: 313px;
        padding: 12px 0;
    }
    .lp-banner .step-img{
        bottom: -30%;
        max-width: 420px;
    }
    .lp-banner {
        padding: 10px 0 20px;
        height: auto;
    }
    .lp-description {
        padding: 15px 40px;
        margin: 130px 0 60px;
    }
    .lp-description p {
        font-size: 15px;
        line-height: 24px;
    }
    .lp-games-section{
        justify-content: center;
    }
    .super-game-cover {
        padding-bottom: 0;
    }
    .super-game-cover>div{
        margin: 0 20px;
    }
    .super-game{
        max-width: 285px;
    }
    .super-game .text-block p{
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        max-width: 259px;
        margin: 15px auto;
    }
    .signup-btn{
        position: relative;
        display: block;
        top: -4px;
        left: 0;
        transform: translate(0);
    }
    .super-game-cover .img.top {
        max-width: 85%;
    }
}

@media (max-width: 380px) {
    .lp-home-cover h1 {
        font-size: 20px;
        line-height: 28px;
    }
    .lp-banner .step-img {
        bottom: -25%;
    }
    .step-text {
        max-width: 90%;
    }
    .step-number {
        height: 40px;
    }
    .step-block {
        padding-top: 22px;
    }
    .step-text h3 {
        font-size: 22px;
    }
    .lp-description p {
        text-align: justify;
    }

    .super-game-cover {
        padding-bottom: 20px;
    }
}
@media (max-width: 365px) {
    .lp-content-cover.games{
        margin-top: 17%;
    }
    .lp-play-now{
        max-width: 286px;
    }
    .step-block img {
        max-width: 111px;
    }
}
@media (max-width: 330px) {
    .lp-home-cover h1 {
        margin: 30px 0 20px;
        font-size: 18px;
        line-height: 21px;
    }
    .step-text p {
        font-size: 12px;
    }
    .lp-content-cover {
        padding: 0 15px;
    }
    .lp-banner .step-img {
        bottom: -20%;
    }
    .lp-description {
        padding: 15px 25px;
        margin: 100px 0 50px;
    }
    .super-game-cover {
        padding-bottom: 60px;
    }
}